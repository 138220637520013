<template>
  <Modal ref="modal" :title="title" :size="'xl'">
    <div>
      <p><strong>Selecteer of deselecteer de gewenste groepen door deze aan te klikken</strong></p>

      <div class="row">
        <ToggleButton
          v-for="option in options"
          :ref="`option_${option.value}`"
          :key="option.value"
          :name="option.name"
          class="col-sm-4"
          @toggle="handleToggle(option.value, $event)"
        />
      </div>
    </div>

    <span slot="footer">
      <button class="btn btn-primary" @click="save">Opslaan</button>
      <button class="btn btn-white" @click="hide">Annuleren</button>
    </span>
  </Modal>
</template>

<script>
import Modal from '../iam/Modal'
import ToggleButton from '../iam/ToggleButton'
import { getContactGroups } from '@/services/contacts'
import { getPropertiesGroup } from '@/services/properties'

export default {
  name: 'ToggleButtonModal',
  props: {
    title: {
      required: true
    },
    clearOnClose: {
      default: false
    },
    type: {
      required: true
    }
  },
  components: {
    Modal,
    ToggleButton
  },
  data () {
    return {
      selectedOptions: [],
      options: []
    }
  },
  methods: {
    async show (optionsToSelect) {
      const response = await this.loadOptions()
      this.initialize(optionsToSelect)
      this.$refs.modal.show()
      return response
    },
    async loadOptions () {
      const response = this.type === 'contacts' ? await getContactGroups({ params: { page_size: 1000 } }) : await getPropertiesGroup()
      const options = response.data.results.map(group => {
        return { value: group.id, name: group.name }
      })
      this.options = options
    },
    clear () {
      this.selectedOptions = []
      if (this.clearOnClose) {
        this.options.forEach(option => {
          this.$refs[`option_${option.value}`][0].setState(false)
        })
      }
    },
    initialize (optionsToSelect = []) {
      this.clear()
      this.selectedOptions = [...optionsToSelect]
      this.selectedOptions.forEach((option) => {
        if (this.options.filter(optionObject => optionObject.value === option).length) {
          this.$refs[`option_${option}`][0].setState(true)
        }
      })
    },
    save () {
      this.$emit('save', this.selectedOptions)
      this.hide()
    },
    hide () {
      this.$refs.modal.hide()
    },
    handleToggle (value, isSelected) {
      const idx = this.selectedOptions.indexOf(value)
      if (isSelected) {
        if (idx === -1) this.selectedOptions.push(value)
      } else {
        if (idx > -1) this.selectedOptions.splice(idx, 1)
      }
    }
  }
}
</script>
