<template>
  <div
    v-if="object.notes.length"
    class="tw-relative tw-cursor-pointer"
    @mouseover="showNotes = true"
    @mouseleave="showNotes = false"
  >
    <i class="fa fa-comment" />
    <div v-show="showNotes" class="tw-absolute tw--top-10 tw-right-0 tw-w-72">
      <pre class="tw-text-xs tw-break-normal tw-whitespace-pre-line">{{ object.notes }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactNotes',
  props: {
    object: {
      type: Object
    }
  },
  data () {
    return {
      showNotes: false
    }
  }
}
</script>
