<template>
  <span class="label" :class="[status, 'tw-rounded-full tw-text-xs']">GDPR</span>
</template>

<script>
export default {
  name: 'ContactGDPR',
  props: ['value', 'object'],
  computed: {
    status () {
      if (this.object.global_unsubscribe) {
        return 'label-danger'
      } else if (this.object.consent_asked) {
        return 'label-primary'
      } else {
        return 'label-warning'
      }
    }
  }
}
</script>
