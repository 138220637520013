<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        v-if="assignTagForm"
        class="tw-w-full tw-h-full tw-fixed tw-inset-0 tw-z-[2050] tw-grid tw-place-items-center tw-bg-overlay tw-overflow-auto"
      >
        <div class="tw-w-full tw-max-w-xl tw-bg-white tw-rounded-lg tw-cursor-auto">
          <div class="tw-p-5 tw-text-center tw-border-b tw-border-gray-cc tw-sticky tw-top-0">
            <h3 aria-label="title" class="tw-inline-block tw-text-xl">
              Selecteer tags
            </h3>
            <button
              aria-label="close"
              type="button"
              class="tw-float-right tw-text-xl tw-p-2"
              @click="hideAssignTagForm"
            >
              <i class="far fa-times" />
            </button>
          </div>
          <FormulateForm
            #default="{ isLoading }"
            name="assignTagsToContacts"
            class="tw-px-4"
            @submit="assignTagsToContacts"
          >
            <FormulateInput
              v-model="selectedTags"
              type="tagselect"
              name="tags"
              label="Tags beheren"
              placeholder="Zoek naar een tag"
              track-by="name"
              tag-placeholder=""
              tag-position="bottom"
              validation="required"
              :exclude-automated-tags="true"
              @tag="openNewTagModal"
            />
            <FormulateErrors />
            <div class="tw-flex tw-flex-row-reverse tw-space-x-4 tw-justify-between tw-items-baseline">
              <span v-if="numberOfSelectedTags" class="tw-text-sm tw-order-1">
                Wijs
                <strong>{{ numberOfSelectedTags }} {{ numberOfSelectedTags > 1 ? 'tags' : 'tag' }}</strong>
                toe aan
                <strong>{{ numberOfContacts }} contacten</strong>.
              </span>
              <FormulateInput type="submit" :disabled="isLoading">
                <i
                  :class="[
                    'fas tw-mr-2',
                    isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
                  ]"
                />
                Opslaan
              </FormulateInput>
            </div>
          </FormulateForm>
        </div>
      </div>
    </transition>
    <AddNewTagModal ref="newTagModal" @tagCreated="selectedTags.push($event)" />
  </div>
</template>

<script>
import AddNewTagModal from '@/components/contacts/AddNewTagModal'
import { bulkAssignTagsToContacts } from '@/services/contacts'
import { successModal } from '@/modalMessages'

export default {
  name: 'AssignTagModal',
  components: {
    AddNewTagModal
  },
  props: {
    contactIds: {
      type: Array
    },
    group: {
      type: Object
    },
    searchParams: {
      type: Object,
      default: () => {}
    },
    contactCount: {
      type: Number
    }
  },
  data () {
    return {
      assignTagForm: false,
      selectedTags: []
    }
  },
  computed: {
    numberOfSelectedTags () {
      return this.selectedTags.length
    },
    numberOfContacts () {
      return this.contactIds?.length || this.group?.count || this.contactCount
    }
  },
  methods: {
    show () {
      this.assignTagForm = true
    },
    openNewTagModal (name) {
      this.$refs.newTagModal.show(name)
    },
    hideAssignTagForm () {
      this.assignTagForm = false
      this.selectedTags = []
    },
    async assignTagsToContacts (data) {
      try {
        let payload = { tag_ids: data.tags.map(tag => tag.id) }
        if (this.group) payload.group = this.group.id
        if (this.contactIds) payload.contact_ids = [...this.contactIds]
        if (this.searchParams) {
          const { ordering, archived, ...search_params } = this.searchParams
          payload = { ordering, archived, search_params, ...payload }
        }

        const response = await bulkAssignTagsToContacts(payload)
        successModal('Tags succesvol toegewezen')
        this.hideAssignTagForm()
        this.$emit('success', { group: this.group, contact_ids: this.contactIds, response })
        return response
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'assignTagsToContacts')
      }
    }
  }
}
</script>
