<template>
  <span v-if="object.city">{{ object.city.name }}</span>
</template>

<script>
export default {
  name: 'ContactCity',
  props: ['value', 'object']
}
</script>

<style scoped>

</style>
