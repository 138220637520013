<!-- DEPRECATED: Homepage for the Contacts module -->
<template>
  <IAMPage>
    <ToggleButtonModal
      ref="groupModal"
      type="contacts"
      :title="'Groepen'"
      :clearOnClose="true"
      @save="handleToggleModalSave"
    />
      <span slot="title">Contacten</span>
      <div>
        <IAMPanel :title="panelTitle" :is-collapsible="false">
          <span slot="toolbar-buttons">
            <div class="btn-group">
              <button
                data-toggle="dropdown"
                aria-expanded="false"
                class="btn btn-default dropdown-toggle"
              >
                Acties <i class="caret" />
              </button>
              <ul class="dropdown-menu pull-right">
                <template v-if="showMergeContacts">
                  <li>
                    <a data-toggle="modal" @click="mergeContacts">
                      <i class="far fa-compress-alt tw-mr-2" /> Contacten samenvoegen
                    </a>
                  </li>
                  <li class="divider" />
                </template>
                <li>
                  <a v-if="selectedGroup" @click="mailGroup('candidate')">
                    <i class="far fa-envelope tw-mr-2" /> Mailen als kandidaat
                  </a>
                </li>
                <li>
                  <a v-if="selectedGroup" @click="mailGroup('bcc')">
                    <i class="far fa-envelope tw-mr-2" /> Mailen als BCC
                  </a>
                </li>
                <li>
                  <a v-if="canExport" @click="exportGroup">
                    <i class="far fa-external-link tw-mr-2" /> Exporteren
                  </a>
                </li>
                <template v-if="selectedGroup && selectedRecords.length">
                  <li>
                    <a @click="showAssignTagModal">
                      <i class="far fa-tag tw-mr-2" /> Tag toewijzen
                    </a>
                  </li>
                  <li>
                    <a @click="showToggleButtonModal">
                      <i class="far fa-copy tw-mr-2" /> Kopiëren naar groep
                    </a>
                  </li>
                  <li>
                    <a @click="deleteContactsFromGroup">
                      <i class="far fa-trash tw-mr-2" /> Verwijderen uit groep
                    </a>
                  </li>
                </template>
              </ul>
            </div>
            <div v-if="selectedGroup">
              <div class="tw-flex tw-flex-wrap tw-w-1/3 tw-mb-2">
                <label class="control-label tw-mr-2 tw-mt-1">Toevoegen aan groep</label>
                <MultipleContactPicker
                  v-model="newContacts"
                  ref="MultipleContactPicker"
                  class="tw-w-full"
                  :rapidMode="true"
                  @rapidSelect="handleRapidSelect"
                />
                <span
                  v-show="isDuplicateContact"
                  class="tw-text-red-500 tw-font-bold tw-my-4"
                >
                  Het contact bestaat al in deze groep.
                </span>
              </div>
            </div>
        </span>
        <DataTable
          v-model="selectedRecords"
          :loading="loading"
          :headers="headers"
          :can-select="true"
          :all-record-ids="allContactIds"
          v-bind="contacts"
          class="tw--m-5 tw-shadow-none"
          @changePage="loadContactsForQuery"
          @allSelected="selectAll"
        >
          <template #item-type="{ item }">
            <i v-if="item.type === 'P'" class="fas fa-user" />
            <button
              v-else
              type="button"
              class="link tw-font-semibold tw-text-left"
              @click="toggleCompanyContacts(item.id)"
            >
              <i class="fas fa-users tw-mr-2" />
              <i :class="['fas', displayCompanyContacts[item.id] ? 'fa-chevron-up' : 'fa-chevron-down']" />
            </button>
          </template>
          <template #item-phone="{ item }">
            <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
          </template>
          <template #item-mobile="{ item }">
            <a :href="`tel:${item.mobile}`">{{ item.mobile }}</a>
          </template>

          <template #record-extend="{ record }">
            <template v-if="record.type === 'B'">
              <tr
                v-for="(contact, index) in record.linked_contacts"
                :key="`${record.id}_child_${contact.id}_${index}`"
                v-show="displayCompanyContacts[record.id]"
                class="tw-border-b tw-border-l tw-bg-gray-50"
              >
                <td class="tw-font-semibold">
                  <input
                    type="checkbox"
                    v-model="selectedLinkedRecords[record.id]"
                    :id="`contact_person_${contact.id}`"
                    :name="`contact_person_${contact.id}`"
                    :value="contact.id"
                  />
                </td>
                <td>{{ contact.link_type || '-' }}</td>
                <td><ContactName :object="contact" /></td>
                <td><ContactCity :object="contact" /></td>
                <td><ContactEmail :object="contact" /></td>
                <td><a :href="`tel:${contact.phone}`">{{ contact.phone }}</a></td>
                <td><a :href="`tel:${contact.mobile}`">{{ contact.mobile }}</a></td>
                <td><ContactNotes :object="contact" /></td>
                <td><ContactGDPR :object="contact" /></td>
              </tr>
            </template>
          </template>
        </DataTable>
      </IAMPanel>
      <ContactMergeModal
        ref="mergeModal"
        :contacts="contactsToMerge"
        @contacts-merged="redirectToMergedContact"
      />
      <AssignTagModal
        ref="assignTagModal"
        :contact-ids="totalSelections"
        @success="handleGroupDelete(selectedGroup.id)"
      />
    </div>
  </IAMPage>
</template>

<script>
import difference from 'lodash/difference'

import IAMPage from '@/components/iam/Page'
import IAMPanel from '@/components/iam/Panel'
import MultipleContactPicker from '@/components/contacts/MultipleContactPicker'
import EventBus from '@/components/iam/bus'
import ToggleButtonModal from '@/components/iam/ToggleButtonModal'
import AssignTagModal from '@/components/contacts/AssignTagModal'
import ContactMergeModal from '@/components/contacts/ContactMergeModal'
import ContactCity from '@/components/contacts/contact_render_components/ContactCity'
import ContactEmail from '@/components/contacts/contact_render_components/ContactEmail'
import ContactGDPR from '@/components/contacts/contact_render_components/ContactGDPR'
import ContactName from '@/components/contacts/contact_render_components/ContactName'
import ContactNotes from '@/components/contacts/contact_render_components/ContactNotes'
import DataTable from '@/components/DataTable'

import { mapGetters, mapActions } from 'vuex'
import {
  errorModal,
  questionModal,
  successModal,
  startLoadingModal,
  stopLoadingModal
} from '@/modalMessages'
import {
  getContacts,
  getContactById,
  getContactsForGroup,
  getContactsGroupById,
  getContactIdsForGroup,
  getContactsByIds,
  deleteContactsFromGroup,
  addContactsToGroup,
  exportContactGroup,
  exportContactSelection,
  getContactLinkedIdsForGroup,
  deleteContactsGroup
} from '@/services/contacts'

export default {
  name: 'ContactList',
  components: {
    DataTable,
    ContactMergeModal,
    IAMPage,
    IAMPanel,
    MultipleContactPicker,
    ToggleButtonModal,
    ContactName,
    ContactCity,
    ContactEmail,
    ContactGDPR,
    ContactNotes,
    AssignTagModal
  },
  data () {
    return {
      loading: false,
      isDuplicateContact: false,
      // The contacts object includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      contacts: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      contactsToMerge: [],
      newContacts: [],
      selectedGroup: null,
      selectedRecords: [],
      selectedLinkedRecords: {},
      allContactIds: [],
      allLinkedIds: [],
      displayCompanyContacts: {}
    }
  },
  computed: {
    ...mapGetters('account', ['collaborator']),
    ...mapGetters('contacts', ['formattedGroups']),

    groupQuery () {
      return this.$route.query.group
    },
    headers () {
      return [
        { text: 'Type', value: 'type' },
        { text: 'Naam', value: 'id', renderComponent: ContactName },
        { text: 'Woonplaats', value: 'residence', renderComponent: ContactCity },
        { text: 'E-mail', value: 'email', renderComponent: ContactEmail },
        { text: 'Telefoon', value: 'phone' },
        { text: 'Gsm', value: 'mobile' },
        { text: 'Commentaar', value: 'notes', renderComponent: ContactNotes },
        { text: 'GDPR', value: 'gdpr', renderComponent: ContactGDPR }
      ]
    },
    canExport () {
      if (this.selectedGroup) {
        return this.collaborator.hasPerm('EXPORT_CONTACT_GROUP') || this.collaborator.hasPerm('EXPORT_CONTACT_GROUP_ALL')
      } else {
        return true
      }
    },
    showMergeContacts () {
      return this.selectedRecords.length > 1 && this.selectedRecords.length < 4
    },
    panelTitle () {
      if (this.selectedGroup) {
        let groupType = ''
        if (this.selectedGroup.is_public) {
          groupType = 'Publieke groep'
        } else if (this.selectedGroup.owner === this.collaborator.id) {
          groupType = 'Eigen Groep'
        } else {
          groupType = 'Gedeelde Groep'
        }
        return `${this.selectedGroup.name} (${groupType})`
      } else {
        return 'Contacten'
      }
    },
    formattedGroupsWithoutCurrentGroup () {
      if (this.groupQuery) return this.formattedGroups.filter(group => group.value !== parseInt(this.groupQuery))
      return this.formattedGroups
    },
    totalSelections () {
      const selectedLinkedRecords = Object.values(this.selectedLinkedRecords).flat()
      return [...this.selectedRecords, ...selectedLinkedRecords]
    }
  },
  watch: {
    selectedRecords (newArr, oldArr) {
      const addedContactId = difference(newArr, oldArr)[0]
      const removedContactId = difference(oldArr, newArr)[0]

      if (!this.allLinkedIds[addedContactId || removedContactId]) return false // Return if the linked contact ids do not exist for selection
      if (addedContactId) this.$set(this.selectedLinkedRecords, addedContactId, this.allLinkedIds[addedContactId])
      else if (removedContactId) this.$set(this.selectedLinkedRecords, removedContactId, [])
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('contacts', ['pollContactExportStatus']),

    showAssignTagModal () {
      this.$refs.assignTagModal.show()
    },
    selectAll (allSelected) {
      if (allSelected) this.selectedLinkedRecords = { ...this.allLinkedIds }
      else {
        Object.keys(this.selectedLinkedRecords).forEach(contactId => {
          this.$set(this.selectedLinkedRecords, contactId, [])
        })
      }
    },
    toggleCompanyContacts (itemId) {
      this.$set(this.displayCompanyContacts, itemId, !this.displayCompanyContacts[itemId])
    },
    async init () {
      try {
        startLoadingModal()
        if (this.groupQuery) {
          const response = await getContactsGroupById(this.groupQuery)
          this.selectedGroup = response.data
        }
        await this.loadContactsForQuery({ params: this.$route.query })
        await this.loadAllContactIds()
        stopLoadingModal()
      } catch (error) {
        console.error(error)
      }
    },
    async loadContacts (payload) {
      const response = await getContacts(payload)
      this.contacts = response.data
      return response
    },
    async loadContactsForGroup (payload) {
      const response = await getContactsForGroup(payload)
      this.contacts = response.data
      return response
    },
    async loadContactsForQuery (payload) {
      try {
        this.loading = true
        let response
        if (this.groupQuery) response = await this.loadContactsForGroup({ groupId: this.groupQuery, url: payload.url })
        else response = await this.loadContacts(payload)
        // Set empty array to collect linked_contact selections, if there is no array
        this.contacts.results.forEach(contact => {
          if (contact.type === 'B' && contact.linked_contacts?.length) {
            if (!this.selectedLinkedRecords[contact.id]?.length) this.$set(this.selectedLinkedRecords, contact.id, [])
          }
        })
        this.loading = false
        return response
      } catch (error) {
        errorModal('Kan contacten niet laden, probeer het opnieuw.')
        throw error
      }
    },

    async loadAllContactIds () {
      try {
        const promises = [getContactLinkedIdsForGroup(this.groupQuery)]
        if (this.groupQuery) {
          promises.push(getContactIdsForGroup(this.groupQuery))
        } else {
          const payload = { params: { ids_only: 1, ...this.$route.query } }
          promises.push(getContacts(payload))
        }
        const [linkedIds, contactIds] = await Promise.all(promises)
        this.allContactIds = contactIds.data
        this.allLinkedIds = linkedIds.data
        return [linkedIds, contactIds]
      } catch (error) {
        console.error(error)
        errorModal('Fout bij laden van contacts-IDs, selecteer alle functionaliteit werkt mogelijk niet. Probeer het opnieuw.')
      }
    },

    async loadContactById (contactId) {
      const response = await getContactById(contactId)
      return response.data
    },
    async mergeContacts () {
      /* Load full contacts, not display contacts */
      const contactPromises = this.selectedRecords.map(contactId => this.loadContactById(contactId))
      let contacts = await Promise.all(contactPromises)

      const contactTypesSame = contacts.every(
        (contact, i, contacts) => contact.type === contacts[0].type
      )
      if (!contactTypesSame) return errorModal('Enkel mogelijk voor contacten van hetzelfde type')

      /* Adds a duplicate to the contacts to merge which results in an array eg. [<contact A>,<contact B>,< contact A>]
         the first contact will be the merged contact and the two subsequent ones will be the ones from which we
         select the features to be merged. */
      contacts = JSON.parse(JSON.stringify(contacts.concat(contacts[0])))

      this.contactsToMerge = contacts
      this.$refs.mergeModal.showModal()
    },
    async deleteContactsFromGroup () {
      try {
        const result = await questionModal('Ben je zeker dat je deze contacten uit de groep wilt verwijderen?')
        if (!(result.value || this.selectedRecords)) return

        const groupId = this.selectedGroup.id
        const contacts = this.selectedRecords

        await deleteContactsFromGroup(groupId, contacts)
        await Promise.all([this.loadContactsForGroup({ groupId }), this.loadAllContactIds()])
        await successModal('De contacten werden uit de groep verwijderd')
      } catch (e) {
        await errorModal('Er ging iets mis bij het verwijderen van het contact uit de groep, gelieve opnieuw te proberen')
      }
    },
    async handleRapidSelect (contact) {
      try {
        if (this.allContactIds.includes(contact.id)) {
          this.isDuplicateContact = true
          return setTimeout(() => { this.isDuplicateContact = false }, 3000)
        }
        const groupId = this.selectedGroup.id
        const payload = {
          group_id_list: [groupId],
          contact_id_list: [contact.id]
        }

        await addContactsToGroup(payload)
        await Promise.all([this.loadContactsForGroup({ groupId }), this.loadAllContactIds()])
        await successModal('Het contact is succesvol toegevoegd')
      } catch (error) {
        errorModal('Er ging iets mis bij het toewijzen van het contact aan de groep, gelieve opnieuw te proberen')
      }
    },
    async mailGroup (target) {
      if (this.totalSelections.length) {
        const params = { contact_ids: [...this.totalSelections] }
        const response = await getContactsByIds(params)
        const selectedContacts = response.data
        const contacts = []
        const sendType = target === 'candidate' ? 'candidate' : 'bcc'
        selectedContacts.forEach((contact) => {
          contacts.push({
            type: sendType,
            contact: contact
          })
        })
        EventBus.$emit('mailclient-prefill-and-show', { contacts })
      } else {
        EventBus.$emit('mailclient-prefill-and-show', {
          contacts: [{
            type: 'group',
            groupId: this.selectedGroup.id
          }]
        })
      }
    },
    async exportGroup () {
      try {
        const payload = {}
        const selectedLinkedRecords = Object.values(this.selectedLinkedRecords).flat()
        const selectedContacts = [...this.selectedRecords, ...selectedLinkedRecords]
        if (this.selectedGroup) payload.group_id = this.selectedGroup.id
        if (selectedContacts.length) payload.selection = selectedContacts
        const win = await this.handleExport(payload)
        if (win) {
          win.focus()
          successModal('De contacten zijn geexporteerd.')
        } else errorModal('Sta pop-ups toe om de export te downloaden.')
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het exporteren van de geselecteerde contacten, gelieve opnieuw te proberen')
      }
    },
    async handleExport (payload) {
      startLoadingModal('Bezig met exporteren van contacten.')
      let response
      if ('selection' in payload) {
        response = await exportContactSelection(payload)
      } else {
        response = await exportContactGroup(payload)
      }
      const jobId = response.data.job_id
      return await this.pollContactExportStatus(jobId)
    },

    async handleToggleModalSave (values) {
      try {
        startLoadingModal('Wijzigingen worden doorgevoerd')
        const contact_id_list = this.selectedRecords
        const group_id_list = [...values]
        await addContactsToGroup({ contact_id_list, group_id_list })
        await successModal('De groepen voor dit contact zijn aangepast.')
      } catch (e) {
        console.log(e)
        await errorModal('Er ging iets verkeerd, kan de groepen waarin dit pand zit niet wijzigen, gelieve opnieuw te proberen')
      }
    },
    showToggleButtonModal () {
      this.$refs.groupModal.show()
    },
    redirectToMergedContact (contactId) {
      this.$router.push({ name: 'ContactDetails', params: { id: contactId } })
    },
    async handleGroupDelete (groupId) {
      try {
        const result = await questionModal('Weet je zeker dat je groep wil verwijderen? De contacten in deze groep zullen NIET worden verwijderd.')
        if (!result.value) return false

        startLoadingModal('Deze groep wordt verwijderd...')
        const response = await deleteContactsGroup(groupId)
        successModal('Deze groep is verwijderd')
        this.$router.push({ name: 'ContactGroups' })
        return response
      } catch {
        errorModal('Kan groep niet verwijderen gelieve opnieuw te proberen')
      }
    }
  }
}
</script>
