<template>
  <router-link :to="{ name: 'ContactDetails', params: { id: object.id } }">{{ object.display_name }}</router-link>
</template>

<script>
export default {
  name: 'ContactName',
  props: ['value', 'object']
}
</script>
