<template>
  <div class="ibox float-e-margins">
    <div class="ibox-title panel-title-bg">
      <h5>{{ title }}
        <small class="m-l-sm">
          <slot name="panel-header-message"></slot>
        </small>
      </h5>
      <div class="ibox-tools">
        <a @click="toggleCollapse" class="collapse-link" v-if="isCollapsible"><i :class="[collapseButtonClass]"
                                                                                 class="fa"></i></a>
        <a class="fullscreen-link" v-if="isExpandable"> <i class="fa fa-expand"></i></a>
        <slot name="toolbar-buttons">
          <a v-if="isCloseable" class="close-link">
            <i class="fa fa-times" />
          </a>
        </slot>
      </div>
    </div>
    <div class="ibox-content" v-if="visible">
      <slot>
        <h2>
          This is standard IN+ Panel<br>
        </h2>
        <p>
          <strong>Lorem ipsum dolor</strong>
          Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
          parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu,
          pretium
          quis, sem. Nulla consequat massa quis enim. </p>
        <p>
          <small>
            Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus
            ut,
            imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer
            tincidunt. Cras dapibus. Vivamus elementum semper nisi.
          </small>
        </p>
      </slot>
    </div>
    <div class="ibox-footer" v-if="showFooter">
      <slot name="footer">
                            <span class="pull-right">
                              The right side of the footer
                        </span>
        This is simple footer example
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    title: {
      type: String,
      default: ''
    },
    isCollapsible: {
      type: Boolean,
      default: true
    },
    isCloseable: {
      type: Boolean,
      default: true
    },
    isExpandable: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: true
    }
  },
  computed: {
    collapseButtonClass () {
      if (this.visible) {
        return 'fa-chevron-up'
      } else {
        return 'fa-chevron-down'
      }
    }
  },
  methods: {
    toggleCollapse () {
      this.visible = !this.visible
    }
  }
}
</script>

<style scoped>
  .panel-title-bg {
    background-color: #f5f5f5;
  }

  .ibox-tools {
    color: #676a6c !important;
  }
</style>
