<template>
  <div class="contact-box center-version" style="margin-bottom: 4px; max-height: 30px;">
    <a style="padding: 2px;" class="btn btn-default"
       v-bind:style="{ backgroundColor: backgroundColor, color: textColor }"
       @click="toggle">
      <h4 class="m-b-xs">
        <i class="glyphicon glyphicon-ok" v-if="selected"></i>
        <strong>{{ name }}</strong>
      </h4>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    name: {
      required: true
    },
    defaultBackgroundColor: {
      required: false,
      default: '#ffffff'
    },
    defaultTextColor: {
      required: false,
      default: '#676a6c'
    },
    selectedBackgroundColor: {
      required: false,
      default: '#1ab394'
    },
    selectedTextColor: {
      required: false,
      default: '#ffffff'
    }
  },

  methods: {
    toggle () {
      this.selected = !this.selected
      this.$emit('toggle', this.selected)
    },
    setState (state) {
      this.selected = state
    }
  },
  data () {
    return {
      selected: false
    }
  },
  computed: {
    backgroundColor () {
      return this.selected ? this.selectedBackgroundColor : this.defaultBackgroundColor
    },
    textColor () {
      return this.selected ? this.selectedTextColor : this.defaultTextColor
    }
  }
}
</script>

<style scoped>

</style>
