<template>
  <div>
    <div class="row wrapper border-bottom white-bg tw-py-4" v-if="hasTitle">
      <div class="col-lg-8">
        <div class="tw-flex tw-gap-x-2 tw-items-center">
          <h2 class="tw-my-0 tw-text-center">
            <slot name="title">IAM Page</slot>
            <img height="34px" src="@/assets/img/loader.gif" v-if="isLoading"/>
          </h2>
          <slot name="extra" />
        </div>
      </div>
      <div class="col-lg-4 tw-flex tw-justify-end">
        <slot name="actionbar"></slot>
      </div>
    </div>
    <div class="row wrapper wrapper-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page',
  props: ['isLoading'],
  computed: {
    hasTitle () {
      return !!this.$slots.title
    }
  }
}
</script>

<style scoped>

</style>
