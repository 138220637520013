<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div :style="divStyle">
    <SuggestionPicker ref="picker" @select="select" v-model="val" v-bind:source="source"
                      v-bind:suggestionRenderComponent="suggestionRenderComponent" v-bind:placeholder="placeholder"
                      v-bind:type="type">
    </SuggestionPicker>
    <span class="col-sm-12" style="padding: 3px;" v-for="(v, index) in vals" :key="index" v-if="!rapidMode">
      <component v-bind:is="selectionRenderComponent" v-model="vals[index]"></component>
      <button ref="removeButton" type="button" class="btn btn-xs btn-danger" @click="deselect(index)"
              style="margin-left: 5px; position: absolute; right: 5px" v-if="showButton(v)">
        <i class="fa fa-times"></i>
      </button>
    </span>
  </div>
</template>

<script>
import SuggestionPicker from './SuggestionPicker'

export default {
  name: 'MultiplePickerMixin',
  components: { SuggestionPicker },
  props: {
    value: {
      default: Array
    },
    placeholder: {
      default: ''
    },
    type: {
      default: ''
    },
    canClear: {
      default: true
    },
    rapidMode: {
      default: false
    }
  },
  data () {
    return {
      vals: JSON.parse(JSON.stringify(this.value)),
      val: null
    }
  },
  computed: {
    divStyle () {
      if (this.val && !this.rapidMode) {
        return 'margin-top:7px; margin-bottom:5px'
      } else {
        return ''
      }
    }
  },
  watch: {
    value (val) {
      this.vals = JSON.parse(JSON.stringify(val))
    }
  },
  methods: {
    select (value) {
      if (this.rapidMode) {
        this.$emit('rapidSelect', value)
        this.$refs.picker.clear()
        this.val = null
      } else {
        this.vals.push(value)
        this.$emit('select', this.vals)
        this.$emit('input', this.vals)
        this.$refs.picker.clear()
        this.val = null
      }
    },
    deselect (index) {
      this.vals.splice(index, 1)
      this.$refs.picker.clear()
      this.val = null
      this.$emit('deselect', this.vals)
      this.$emit('input', this.vals)
    },
    disable () {
      this.$refs.picker.disable()
    },
    enable () {
      this.$refs.picker.enable()
    },
    setError () {
      this.$refs.picker.setError()
    },
    removeError () {
      this.$refs.picker.removeError()
    },
    clear () {
      this.$refs.picker.clear()
      this.vals = []
      this.$emit('input', this.vals)
    },
    showButton (val) {
      return val && this.canClear
    }
  }
}
</script>

<style scoped>

</style>
