<template>
  <BaseModal ref="modal" title="Nieuwe tag maken" max-width="tw-max-w-sm">
    <FormulateForm
      #default="{ isLoading }"
      v-model="newTag"
      name="contactTagCreate"
      @submit="addNewTag"
    >
      <FormulateInput
        name="name"
        label="Naam"
        placeholder="Naam"
        validation="required:trim"
      />
      <fieldset class="tw-border-none tw-p-0">
        <legend class="formulate-label">Categorie</legend>
        <div class="tw-mt-1 tw-flex tw-flex-wrap tw-gap-2">
          <label
            v-for="color in tagColors"
            :key="color.id"
            :for="`color_${color.id}`"
            v-tooltip="color.category"
            :style="`background: ${color.hex_value}`"
            class="tw-px-1.25 tw-py-0.5 tw-rounded-md tw-text-center tw-shadow-md tw-cursor-pointer tw-transform hover:tw-scale-125"
          >
            <input
              v-model="newTag.color"
              :id="`color_${color.id}`"
              :value="color"
              type="radio"
              name="color"
              class="tw-sr-only"
            />
            <i
              class="fas fa-check tw-text-sm"
              :style="`
                color: ${
                  (newTag.color && newTag.color.id === color.id)
                  ? getTagTextColor(color.hex_value)
                  : 'transparent'
                }
              `"
            />
          </label>
        </div>
      </fieldset>

      <FormulateErrors />

      <div class="tw-mt-4 tw-flex tw-flex-row tw-space-x-4 tw-justify-between tw-items-baseline">
        <span
          v-if="newTag.color"
          :style="`
            background: ${newTag.color.hex_value};
            color: ${getTagTextColor(newTag.color.hex_value)};
          `"
          class="tag"
        >
          {{ newTag.name }}
        </span>
        <FormulateInput type="submit" :disabled="isLoading">
          <i
            :class="[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
      </div>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { VTooltip } from 'floating-vue'
import { getTagTextColor } from '@/utils/helpers'
import { getContactTagColors, createContactTag } from '@/services/contacts'
import { successModal } from '@/modalMessages'

export default {
  name: 'AddNewTagModal',
  directives: {
    tooltip: VTooltip
  },
  data () {
    return {
      tagColors: [],
      newTag: {}
    }
  },
  methods: {
    getTagTextColor,
    async show (name) {
      try {
        const response = await getContactTagColors()
        this.tagColors = response.data?.results
        this.newTag = { name, color: this.tagColors[0] }
        this.$refs.modal.show()
      } catch (error) {
        console.error(error)
      }
    },
    async addNewTag (payload) {
      try {
        payload.color = payload.color.id
        const response = await createContactTag(payload)
        const tag = response.data
        this.$emit('tagCreated', tag)
        this.$refs.modal.hide()
        successModal('Nieuwe tag gemaakt')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'contactTagCreate')
      }
    }
  }
}
</script>
