<script>
import { contacts } from '@/components/iam/SearchProviders'
import MultiplePickerMixin from '@/components/iam/MultiplePickerMixin'
import ContactRenderer from '@/components/iam/ContactPickerRenderComponent'

export default {
  name: 'ContactPicker',

  mixins: [MultiplePickerMixin],
  props: {
    placeholder: {
      default () {
        return 'Selecteer een contact'
      }
    },
    selectionRenderComponent: {
      default () {
        return ContactRenderer
      }
    },
    type: {
      default () {
        return 'contacts'
      }
    }
  },
  data () {
    return {
      source: contacts.ttAdapter(),
      suggestionRenderComponent: function (value) {
        return `
          <div>
            <strong>${value.display_name}</strong><br>
            <i class="fa fa-phone"></i> ${value.phone || '<em>geen telefoon</em>'}<br>
            <i class="fa fa-mobile"></i> ${value.mobile || '<em>geen gsm</em>'}<br>
            <i class="fa fa-envelope"></i> ${value.email || '<em>geen email</em>'}<br>
          </div>`
      }

    }
  }
}
</script>

<style scoped>
</style>
