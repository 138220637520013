var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Nieuwe tag maken","max-width":"tw-max-w-sm"}},[_c('FormulateForm',{attrs:{"name":"contactTagCreate"},on:{"submit":_vm.addNewTag},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"name":"name","label":"Naam","placeholder":"Naam","validation":"required:trim"}}),_c('fieldset',{staticClass:"tw-border-none tw-p-0"},[_c('legend',{staticClass:"formulate-label"},[_vm._v("Categorie")]),_c('div',{staticClass:"tw-mt-1 tw-flex tw-flex-wrap tw-gap-2"},_vm._l((_vm.tagColors),function(color){return _c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(color.category),expression:"color.category"}],key:color.id,staticClass:"tw-px-1.25 tw-py-0.5 tw-rounded-md tw-text-center tw-shadow-md tw-cursor-pointer tw-transform hover:tw-scale-125",style:(("background: " + (color.hex_value))),attrs:{"for":("color_" + (color.id))}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newTag.color),expression:"newTag.color"}],staticClass:"tw-sr-only",attrs:{"id":("color_" + (color.id)),"type":"radio","name":"color"},domProps:{"value":color,"checked":_vm._q(_vm.newTag.color,color)},on:{"change":function($event){return _vm.$set(_vm.newTag, "color", color)}}}),_c('i',{staticClass:"fas fa-check tw-text-sm",style:(("\n              color: " + ((_vm.newTag.color && _vm.newTag.color.id === color.id)
                ? _vm.getTagTextColor(color.hex_value)
                : 'transparent') + "\n            "))})])}),0)]),_c('FormulateErrors'),_c('div',{staticClass:"tw-mt-4 tw-flex tw-flex-row tw-space-x-4 tw-justify-between tw-items-baseline"},[(_vm.newTag.color)?_c('span',{staticClass:"tag",style:(("\n          background: " + (_vm.newTag.color.hex_value) + ";\n          color: " + (_vm.getTagTextColor(_vm.newTag.color.hex_value)) + ";\n        "))},[_vm._v(" "+_vm._s(_vm.newTag.name)+" ")]):_vm._e(),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading}},[_c('i',{class:[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Opslaan ")])],1)]}}]),model:{value:(_vm.newTag),callback:function ($$v) {_vm.newTag=$$v},expression:"newTag"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }