<template>
  <a :href="mailToUrl">{{ object.email }}</a>
</template>

<script>
export default {
  name: 'ContactEmail',
  props: ['object', 'value'],
  computed: {
    mailToUrl () {
      return `mailto:${this.object.email}`
    }
  }
}
</script>
